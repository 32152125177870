import { gql, useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useSnackbar } from '@flock/shared-ui'
import { AdminAddressValuationsFetchOfferPriceCompsDocument } from '@flock/flock-gql-server/src/__generated__/graphql'

import { FetchOfferCompsModalProps } from './fetchOfferCompsModalTypes'

export const FETCH_OFFER_COMPS = gql`
  mutation AdminAddressValuationsFetchOfferPriceComps(
    $input: Core_FetchOfferPriceCompsRequestInput!
  ) {
    fetchOfferPriceComps(input: $input) {
      comps {
        source
        similarityScore
        url
        avmPrice
        squareFootage
        address
        beds
        baths
        halfBaths
        yearBuild
        notes
        estimatedRemodelCost
        soldDate
        uuid
        addressUuid
        selected
        updatedAt
        distance
      }
    }
  }
`

const useFetchOfferCompsModal = (props: FetchOfferCompsModalProps) => {
  const { address, onClose, parseOfferCompsForPrefill } = props

  const { notify } = useSnackbar()

  const [fetchOfferComps, { data: comps, loading }] = useMutation(
    AdminAddressValuationsFetchOfferPriceCompsDocument
  )

  useEffect(() => {
    parseOfferCompsForPrefill(comps?.fetchOfferPriceComps?.comps)
  }, [comps, parseOfferCompsForPrefill])

  const submitFetchOfferComps = async () => {
    try {
      await fetchOfferComps({
        variables: {
          input: {
            formattedAddress: address,
          },
        },
      })
      notify('Offer comps successfully fetched', 'success')
      onClose()
    } catch (e) {
      notify('Failed to fetch offer comps, please try again', 'error')
    }
  }

  return {
    submitFetchOfferComps,
    loading,
  }
}

export default useFetchOfferCompsModal
