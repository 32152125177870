import React, { useState, useEffect, useCallback } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  styled,
  Tooltip,
} from '@mui/material'
import {
  Button,
  Typography,
  flockColors,
  Grid,
  Checkbox,
  LoadingCard,
  useSnackbar,
  useRegister,
} from '@flock/flock-component-library'

import { ExpandMore } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { navigate, RouteComponentProps, useParams } from '@reach/router'
import { useQuery } from '@apollo/client'
import { formatAddressString } from '@flock/utils'
import {
  AdminGetDocumentDownloadUrlDocument,
  AdminGetOrderDocument,
  Core_Order,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { ORDER_ONBOARDING_URL } from '../../constants'

const PageWrapper = styled('div')({
  paddingBottom: '8rem',
})

const PageTitle = styled(Typography)({
  marginTop: '4rem',
  marginBottom: '2rem',
  display: 'flex',
})

const FieldLabel = styled(Typography)({
  fontWeight: 'bold',
  opacity: 0.5,
})

const StyledSummary = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
})

type LabelledFieldProps = {
  label: string
  value: string
}

const LabelledField = (props: LabelledFieldProps) => {
  const { label, value } = props
  return (
    <Grid item xs={3} flexDirection="column">
      <FieldLabel>{label}</FieldLabel>
      <Typography variant="h2">{value}</Typography>
    </Grid>
  )
}

const statusColors: { [key: string]: string } = {
  completed: flockColors.aquamarine,
  'in progress': flockColors.blue,
  'not started': flockColors.darkGray,
}

type IndividualOrderPageProps = RouteComponentProps & {
  orderUuid?: string
}

const IndividualOrderPage = (props: IndividualOrderPageProps) => {
  let { orderUuid } = useParams()
  const { orderUuid: orderUuidProp } = props
  if (orderUuidProp) {
    orderUuid = orderUuidProp
  }

  const { register } = useForm({
    mode: 'onSubmit',
  })

  const [documentInfo, setDocumentInfo] = useState({
    legalEntityUuid: '',
    documentUuid: '',
  })

  const { notify } = useSnackbar()

  const {
    error,
    loading: orderLoading,
    data: orderData,
  } = useQuery(AdminGetOrderDocument, {
    variables: {
      input: {
        orderUuid,
      },
    },
    onError: () => {
      notify('Failed to get order', 'error')
    },
  })

  const { refetch } = useQuery(AdminGetDocumentDownloadUrlDocument, {
    skip: true,
  })

  const downloadDocument = useCallback(async () => {
    try {
      const urlData = await refetch({
        input: {
          legalEntityUuid: documentInfo.legalEntityUuid,
          documentUuid: documentInfo.documentUuid,
        },
      })
      window.open(
        urlData?.data?.legalEntityDocumentPresignedUrl?.presignedUrl as string,
        '_blank'
      )
    } catch (e) {
      notify(
        'An error while downloading the file. Please refresh or try again.',
        'error'
      )
    }
  }, [documentInfo, notify, refetch])

  useEffect(() => {
    // prevents document fetching on initial render
    if (
      documentInfo.legalEntityUuid !== '' &&
      documentInfo.documentUuid !== ''
    ) {
      downloadDocument()
    }
  }, [documentInfo, downloadDocument])

  const setDocumentInfoFromTaskResolutionData = (task: any) => {
    try {
      const resolutionData = JSON.parse(task.resolutionData.data)

      if (resolutionData.contribution_agreement_signed_document_uuid) {
        setDocumentInfo({
          legalEntityUuid: orderData?.order?.order?.legalEntityUuid as string,
          documentUuid:
            resolutionData.contribution_agreement_signed_document_uuid,
        })
      }

      if (resolutionData.subscription_booklet_signed_document_uuid) {
        setDocumentInfo({
          legalEntityUuid: orderData?.order?.order?.legalEntityUuid as string,
          documentUuid:
            resolutionData.subscription_booklet_signed_document_uuid,
        })
      }
    } catch (e) {
      notify('No document data for this order.', 'error')
    }
  }

  if (orderLoading) {
    return <LoadingCard text="Loading Order..." />
  }

  if (error) {
    return (
      <LoadingCard
        text="Failed to find order. Please refresh or contact engineering if the issue persists."
        hideLoader
      />
    )
  }

  const { contributorName, progress, createdAt, updatedAt, targetCloseDate } =
    orderData?.order?.order as Core_Order

  const createdAtDate = new Date(createdAt)
  const updatedAtDate = new Date(updatedAt)
  let targetClose = targetCloseDate ? new Date(targetCloseDate) : null
  if (!targetClose || targetClose?.getFullYear() < 2000) {
    targetClose = null
  }

  return (
    <PageWrapper>
      <PageTitle variant="h1">Order Tasks</PageTitle>
      <Grid container sx={{ marginBottom: '2rem' }}>
        <LabelledField label="Contributor Name" value={contributorName} />
        <LabelledField
          label="Date Created"
          value={createdAtDate.toLocaleDateString('en-US')}
        />
        <LabelledField
          label="Date Updated"
          value={updatedAtDate.toLocaleDateString('en-US')}
        />
        {targetClose && (
          <LabelledField
            label="Target Close Date"
            value={targetClose.toLocaleDateString('en-US')}
          />
        )}
      </Grid>
      {progress.map((phaseData: any) => {
        const { name, address, status, workflows } = phaseData
        return (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <StyledSummary>
                <Typography variant="h3">
                  {name}
                  {address && (
                    <Typography variant="h3" sx={{ fontWeight: 'normal' }}>
                      {formatAddressString(address)}
                    </Typography>
                  )}
                </Typography>
                <Chip
                  label={status}
                  sx={{
                    textTransform: 'capitalize',
                    color: 'white',
                    backgroundColor: statusColors[status],
                    pointerEvents: 'none',
                  }}
                />
              </StyledSummary>
            </AccordionSummary>
            <AccordionDetails>
              {workflows.map((workflow: any) => (
                <>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {workflow.name || ''}
                  </Typography>
                  {workflow.tasks.map((task: any) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const checkboxProps = useRegister(register, task.uuid)

                    if (
                      task.key === 'contribution-agreement-signed' &&
                      task.completed
                    ) {
                      return (
                        <>
                          <Tooltip
                            title={
                              task.customerActionDescription && (
                                <div style={{ fontSize: '1rem' }}>
                                  {`Customer sees: ${task.customerActionDescription}`}
                                </div>
                              )
                            }
                            placement="top"
                            arrow
                          >
                            <div>
                              <Checkbox
                                label={task.adminActionDescription}
                                defaultChecked={task.completed}
                                disabled
                                {...checkboxProps}
                              />
                            </div>
                          </Tooltip>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setDocumentInfoFromTaskResolutionData(task)
                            }}
                            sx={{
                              marginLeft: '2rem',
                              marginTop: '0.5rem',
                              marginBottom: '0.5rem',
                            }}
                          >
                            Download Signed Contribution Agreement
                          </Button>
                        </>
                      )
                    } else if (
                      task.key === 'property-questionnaire-completed' &&
                      task.completed
                    ) {
                      return (
                        <>
                          <Tooltip
                            title={
                              task.customerActionDescription && (
                                <div style={{ fontSize: '1rem' }}>
                                  {`Customer sees: ${task.customerActionDescription}`}
                                </div>
                              )
                            }
                            placement="top"
                            arrow
                          >
                            <div>
                              <Checkbox
                                label={task.adminActionDescription}
                                defaultChecked={task.completed}
                                disabled
                                {...checkboxProps}
                              />
                            </div>
                          </Tooltip>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              navigate(
                                `${ORDER_ONBOARDING_URL}/${orderUuid}/tasks/${task.uuid}`
                              )
                            }}
                            sx={{
                              marginLeft: '2rem',
                              marginTop: '0.5rem',
                              marginBottom: '0.5rem',
                            }}
                          >
                            Questionnaire Answers
                          </Button>
                        </>
                      )
                    } else if (task.key === 'subscription-documents-signed') {
                      return (
                        <>
                          <Tooltip
                            title={
                              task.customerActionDescription && (
                                <div style={{ fontSize: '1rem' }}>
                                  {`Customer sees: ${task.customerActionDescription}`}
                                </div>
                              )
                            }
                            placement="top"
                            arrow
                          >
                            <div>
                              <Checkbox
                                label={task.adminActionDescription}
                                defaultChecked={task.completed}
                                disabled
                                {...checkboxProps}
                              />
                            </div>
                          </Tooltip>
                          {task.completed && (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setDocumentInfoFromTaskResolutionData(task)
                              }}
                              sx={{
                                marginLeft: '2rem',
                                marginTop: '0.5rem',
                                marginBottom: '0.5rem',
                              }}
                            >
                              Download Signed Subscription Booklet
                            </Button>
                          )}
                        </>
                      )
                    } else {
                      return (
                        <Tooltip
                          title={
                            task.customerActionDescription && (
                              <div style={{ fontSize: '1rem' }}>
                                {`Customer sees: ${task.customerActionDescription}`}
                              </div>
                            )
                          }
                          placement="top"
                          arrow
                        >
                          <div>
                            <Checkbox
                              label={task.adminActionDescription}
                              defaultChecked={task.completed}
                              disabled
                              {...checkboxProps}
                            />
                          </div>
                        </Tooltip>
                      )
                    }
                  })}
                </>
              ))}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </PageWrapper>
  )
}

IndividualOrderPage.defaultProps = {
  orderUuid: undefined,
}

export default IndividualOrderPage
