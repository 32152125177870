import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/shared-ui'
import {
  AdminSingleFamilyValuationSummaryGenerateInvestmentMemoDocument,
  Core_ValuationV1,
  Core_ValuationType,
  Core_InvestmentMemoType,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { formatDollarsAndCents } from '@flock/utils'
import { ValuationSummaryProps } from './valuationSummaryTypes'

export const GENERATE_INVESTMENT_MEMO = gql`
  mutation AdminSingleFamilyValuationSummaryGenerateInvestmentMemo(
    $input: Core_GenerateInvestmentMemoRequestInput!
  ) {
    generateInvestmentMemo(input: $input) {
      url
    }
  }
`

const useValuationSummary = (props: ValuationSummaryProps) => {
  const { notify } = useSnackbar()

  const {
    computedValuationResult,
    operatorUuid: operatorUuidProp,
    valuationInputs,
    leadUuid,
    selfReportedValue,
  } = props

  const [valuationTrailOpen, setValuationTrailOpen] = useState(false)
  const [submitValuationModalOpen, setSubmitValuationModalOpen] =
    useState(false)
  const [computeCashFlowModalOpen, setComputeCashFlowModalOpen] =
    useState(false)

  const [generateInvestmentMemo, { loading: investmentMemoLoading }] =
    useMutation(AdminSingleFamilyValuationSummaryGenerateInvestmentMemoDocument)

  const downloadInvestmentMemo = async () => {
    const operatorUuid =
      operatorUuidProp || computedValuationResult?.operatorUuid
    try {
      const urlData = await generateInvestmentMemo({
        variables: {
          input: {
            leadUuid,
            property: {
              uuid: valuationInputs?.property?.uuid,
              addressUuid: valuationInputs?.property?.addressUuid,
              baths: valuationInputs?.property?.baths,
              beds: valuationInputs?.property?.beds,
              halfBaths: valuationInputs?.property?.halfBaths,
              propertyType: valuationInputs?.property?.propertyType,
              sqft: valuationInputs?.property?.sqft,
              yearBuilt: valuationInputs?.property?.yearBuilt,
              notes: valuationInputs?.property?.notes,
            },
            operatorUuid: operatorUuid as string,
            finalOfferPrice: Math.trunc(
              computedValuationResult?.outputs?.netYieldAdjustedOfferPrice || 0
            ),
            memoType: Core_InvestmentMemoType.InvestmentMemoTypeSingleFamily,
          },
        },
      })
      window.open(
        urlData?.data?.generateInvestmentMemo?.url as string,
        '_blank'
      )
    } catch (e) {
      notify(
        'An error while downloading the document. Please refresh or try again.',
        'error'
      )
    }
  }

  // if selfReportedValue = 0, NaN comparisons, e.g. NaN < 0.2 and NaN > 0.2 return false
  const selfReportedFinalOfferPricePercentDiff =
    (selfReportedValue -
      (computedValuationResult?.outputs?.finalOfferPrice ||
        selfReportedValue)) /
    selfReportedValue

  const finalOfferPriceOutsideThreshold =
    selfReportedFinalOfferPricePercentDiff > 0.2

  const msaYieldDoesNotExist =
    computedValuationResult?.outputs?.msaLevelMinimums === 0

  const yieldConstrainedOfferPriceOutsideOfAvmOrCMAPriceDiff =
    ((computedValuationResult?.outputs?.finalOfferPrice || 0) -
      (computedValuationResult?.outputs?.netYieldAdjustedOfferPrice || 0)) /
    (computedValuationResult?.outputs?.finalOfferPrice || 0)

  const yieldConstrainedOfferPriceOutsideOfAvmOrCMAPrice =
    yieldConstrainedOfferPriceOutsideOfAvmOrCMAPriceDiff > 0.2

  let comparativeAnalysisRequired = msaYieldDoesNotExist

  if (selfReportedValue === 0 || selfReportedValue === undefined) {
    comparativeAnalysisRequired =
      comparativeAnalysisRequired ||
      yieldConstrainedOfferPriceOutsideOfAvmOrCMAPrice
  }

  let offerThresholdErrorMessage = ''
  if (yieldConstrainedOfferPriceOutsideOfAvmOrCMAPrice) {
    offerThresholdErrorMessage = `Yield constrained offer price differs by more than 20% from the AVM (or cma) value of ${formatDollarsAndCents(
      computedValuationResult?.outputs?.finalOfferPrice || 0
    )}.`
  } else if (msaYieldDoesNotExist) {
    offerThresholdErrorMessage =
      'MSA yield minimum does not exist. Please add the MSA to the assumptions.'
  }

  const getValuationTypeString = (valuation: Core_ValuationV1 | undefined) => {
    if (valuation?.type === Core_ValuationType.ValuationTypeFinal) {
      return 'final'
    }
    return 'initial'
  }

  return {
    downloadInvestmentMemo,
    investmentMemoLoading,
    valuationTrailOpen,
    setValuationTrailOpen,
    finalOfferPriceOutsideThreshold,
    comparativeAnalysisRequired,
    submitValuationModalOpen,
    setSubmitValuationModalOpen,
    computeCashFlowModalOpen,
    setComputeCashFlowModalOpen,
    offerThresholdErrorMessage,
    valuationTypeString: getValuationTypeString(computedValuationResult),
  }
}

export default useValuationSummary
