import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import {
  AdminCreateTaskDocument,
  AdminGetValuationDocument,
  AdminSearchLeadsDocument,
  Core_Customer,
  Core_Lead,
  Core_ValidatedAddress,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  Button,
  Typography,
  useSnackbar,
  flockColors,
  DataTable,
  LoadingCard,
} from '@flock/flock-component-library'
import {
  Modal,
  Paper,
  styled,
  Box,
  ThemeProvider,
  Grid,
  TableRow,
  TableCell,
  InputAdornment,
  debounce,
  TextField,
} from '@mui/material'
import { formatAddressString, formatCityStateZip } from '@flock/utils'
import { useQuery, useMutation } from '@apollo/client'
import {
  AnyInputConfig,
  GridForm,
  InputType,
} from '@flock/shared-ui/src/components/GridForm'
import { flockTheme } from '@flock/shared-ui'
import { Add, Remove, Search } from '@mui/icons-material'
import { navigate } from 'gatsby'
import { NEW_CONTRIBUTIONS_URL } from '../../constants'

const SearchWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '1rem',
})

const SearchField = styled(TextField)({
  width: '100%',

  '& > div > input': {
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
  },
})

const SectionTitleContainer = styled('div')({
  marginTop: '2rem',
  marginBottom: '1rem',
})

type CustomerRequestContributionAgreementModalProps = {
  customer: Core_Customer
}

type UnitsEntry = {
  UUID: string
  FormattedAddress: string
  Unit: string
}

type LeadTableEntry = {
  UUID: string
  AddressUUID: string
  FormattedAddress: string
  FullName: string
  FinalOfferPrice: string
  Units: UnitsEntry[]
}

const RemoveTableCell = styled(TableCell)({
  backgroundColor: flockColors.red,
  opacity: '75%',
  width: '1.5rem',
  textAlign: 'center',
  color: flockColors.white,
})
const AddTableCell = styled(TableCell)({
  opacity: '75%',
  width: '1.5rem',
  textAlign: 'center',
  color: flockColors.white,
})

export const leadColumns = [
  {
    name: 'UUID',
    options: { display: false },
  },
  {
    name: 'FormattedAddress',
    label: 'Address',
    options: { sortThirdClickReset: true },
  },
  {
    name: 'FullName',
    label: 'Name',
  },
  {
    name: 'FinalOfferPrice',
    label: 'Final Offer Price',
  },
]

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '60vw',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

const CustomerRequestContributionAgreementModal = ({
  customer,
}: CustomerRequestContributionAgreementModalProps) => {
  const [open, setOpen] = useState(false)
  const [formattedLeadsData, setFormattedLeadsData] = useState<
    LeadTableEntry[]
  >([])

  const [selectedLeads, setSelectedLeads] = useState<LeadTableEntry[]>([])
  const [offerPrice, setOfferPrice] = useState<number>(0)

  const querySearch = customer.fullName || ''
  const [searchString, setSearchString] = useState(querySearch.toLowerCase())

  const [createTask] = useMutation(AdminCreateTaskDocument)

  const { refetch } = useQuery(AdminGetValuationDocument, {
    skip: true,
  })

  const removeLead = async (leadUuid: string) => {
    const selectedLeadsSet = new Set(selectedLeads)
    Array.from(selectedLeadsSet).forEach((leadRow) => {
      if (leadRow.UUID === leadUuid) {
        selectedLeadsSet.delete(leadRow)
      }
    })

    const updatedSelectedLeads = Array.from(selectedLeadsSet)
    setSelectedLeads(updatedSelectedLeads)
    const valuationData = await refetch({
      input: {
        leadUuid,
      },
    })
    setOfferPrice(
      offerPrice -
        (valuationData?.data?.valuation?.valuation?.finalOfferPrice || 0)
    )
  }
  const addLead = async (leadToAdd: LeadTableEntry) => {
    const selectedLeadsSet = new Set(selectedLeads)
    selectedLeadsSet.add(leadToAdd)
    const updatedSelectedLeads = Array.from(selectedLeadsSet)
    setSelectedLeads(updatedSelectedLeads)
    const valuationData = await refetch({
      input: {
        leadUuid: leadToAdd.UUID,
      },
    })
    const finalValuation = valuationData?.data?.valuation?.valuation
    setOfferPrice(offerPrice + (finalValuation?.finalOfferPrice || 0))
  }

  const leadTableOptions = {
    filter: false,
    download: true,
    print: false,
    viewColumns: false,
    search: false,
    sort: true,
    selectableRows: 'multiple',
    responsive: 'standard',
    elevation: 0,
    customRowRender: (data: any, dataIndex: number) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
      const [_, formattedAddress, fullName, finalOfferPrice] = data
      const leadEntry = formattedLeadsData[dataIndex]
      const isSelected = selectedLeads.includes(leadEntry)
      const formattedFinalOfferPrice = finalOfferPrice
        ? `$${Number(finalOfferPrice).toLocaleString()}`
        : ''

      return (
        <TableRow>
          <AddTableCell
            onClick={() => {
              addLead(leadEntry)
            }}
            style={{
              cursor: isSelected ? 'default' : 'pointer',
              backgroundColor: isSelected
                ? flockColors.darkGray
                : flockColors.green,
            }}
          >
            <Add />
          </AddTableCell>

          <TableCell>{formattedAddress}</TableCell>
          <TableCell>{fullName}</TableCell>
          <TableCell>{formattedFinalOfferPrice}</TableCell>
        </TableRow>
      )
    },
    // searchOpen: true,
  }

  const selectedLeadTableOptions = {
    download: true,
    print: false,
    viewColumns: false,
    responsive: 'standard',
    elevation: 0,
    customRowRender: (data: any) => {
      const [uuid, formattedAddress, fullName, finalOfferPrice] = data

      const formattedFinalOfferPrice = finalOfferPrice
        ? `$${Number(finalOfferPrice).toLocaleString()}`
        : ''
      return (
        <TableRow>
          <RemoveTableCell
            onClick={() => {
              removeLead(uuid)
            }}
            style={{ cursor: 'pointer' }}
          >
            <Remove />
          </RemoveTableCell>

          <TableCell>{formattedAddress}</TableCell>
          <TableCell>{fullName}</TableCell>
          <TableCell>{formattedFinalOfferPrice}</TableCell>
        </TableRow>
      )
    },
    // searchOpen: true,
  }

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const { notify } = useSnackbar()

  const { loading: leadsLoading } = useQuery(AdminSearchLeadsDocument, {
    variables: {
      searchLeadsInput: {
        searchString,
      },
    },
    onError: () => {
      notify('Failed to get leads', 'error')
    },
    onCompleted: (result) => {
      const leads = result?.searchLeads?.leads

      const formattedLeadData: LeadTableEntry[] = []
      // iterate through leads and check if lead.address.units is empty
      // if empty, then don't add to formattedLeadData
      leads?.forEach((leadEntry: Core_Lead | null) => {
        const units: UnitsEntry[] = []

        const unitsData = leadEntry?.address?.units

        unitsData?.forEach((unit) => {
          units.push({
            UUID: unit?.uuid || '',
            FormattedAddress: unit?.formattedAddress || '',
            Unit: unit?.unit || '',
          })
        })

        formattedLeadData.push({
          UUID: leadEntry?.uuid || '',
          AddressUUID: leadEntry?.address?.uuid || '',
          FormattedAddress: `${formatAddressString(
            leadEntry?.address as Core_ValidatedAddress
          )} ${formatCityStateZip(
            leadEntry?.address as Core_ValidatedAddress
          )}`,
          FullName: `${leadEntry?.fullName || ''}`,
          FinalOfferPrice: `${
            leadEntry?.valuationObject?.finalOfferPrice || ''
          }`,
          Units: units,
        })
      })

      setFormattedLeadsData(formattedLeadData)
    },
  })

  // parse leads data

  const setSearchStringToSearchFieldInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchString(e.target.value.toLowerCase())
    },
    []
  )

  const debounceSetSearchStringToSearchFieldInput = useMemo(
    () => debounce(setSearchStringToSearchFieldInput, 700),
    [setSearchStringToSearchFieldInput]
  )

  const inputConfigs: AnyInputConfig[] = [
    {
      name: 'titleDescription',
      required: true,
      type: InputType.Section,
      gridItemProps: {
        sm: 12,
      },
      props: {
        title: 'Order Name',
        inputConfigs: [
          {
            name: 'accreditedBy',
            type: InputType.CustomComponent,
            props: {
              component: (
                <Typography variant="p2">
                  Please enter the name this order will be referenced by
                </Typography>
              ),
            },
          },
        ],
      },
    },
    {
      name: 'orderName',
      required: true,
      type: InputType.Text,
      defaultValue: selectedLeads[0]?.FullName || '',
      gridItemProps: {
        sm: 12,
      },
      props: {
        label: 'Order Name',
      },
    },
    {
      name: 'offerPrice',
      required: true,
      type: InputType.Text,
      gridItemProps: {
        sm: 6,
      },
      props: {
        label: `Offer Price: $${offerPrice.toLocaleString()}`,
        format: 'dollarsAndCents',
      },
    },
    {
      name: 'targetCloseDate',
      required: true,
      type: InputType.DatePicker,
      gridItemProps: {
        sm: 6,
      },
      props: {
        label: 'Target Close Date',
      },
    },
    {
      name: 'description',
      required: true,
      type: InputType.Section,
      gridItemProps: {
        sm: 12,
      },
      props: {
        title: 'Address Lease Information',
        inputConfigs: [
          {
            name: 'accreditedBy',
            type: InputType.CustomComponent,
            props: {
              component: (
                <Typography variant="p2">
                  Please select the following addresses that have lease
                  information
                </Typography>
              ),
            },
          },
        ],
      },
    },
  ]

  selectedLeads.forEach((leadEntry) => {
    if (leadEntry.Units.length > 0) {
      leadEntry.Units.forEach((unit) => {
        inputConfigs.push({
          name: unit.UUID,
          type: InputType.Checkbox,
          gridItemProps: {
            sm: 6,
          },
          props: {
            label: unit.FormattedAddress,
          },
        })
      })
    } else {
      inputConfigs.push({
        name: leadEntry.AddressUUID,
        type: InputType.Checkbox,
        gridItemProps: {
          sm: 6,
        },
        props: {
          label: leadEntry.FormattedAddress,
        },
      })
    }
  })

  const onSubmit = async (result: any) => {
    const addressesAndUnitsWithLeaseInfoData: string[] = []
    const selectedLeadUuids: string[] = []
    const selectedAddressUuids: string[] = []
    selectedLeads.forEach((leadEntry) => {
      selectedLeadUuids.push(leadEntry.UUID)
      selectedAddressUuids.push(leadEntry.AddressUUID)
    })

    /*
      we want to create a map of addressUuids as the key to an array of unitUuids that have lease info
    */

    // iterate through result fields to find lease info for address/unit uuids
    Object.keys(result).forEach((key: any) => {
      if (/[0-9]/.test(key)) {
        if (result[key] === true) {
          addressesAndUnitsWithLeaseInfoData.push(key)
        }
      }
    })

    // construct the mapping in AddressLeaseInfo
    const AddressLeaseInfo: { [key: string]: string[] } = {}

    selectedLeads.forEach((leadEntry) => {
      const addressUuid = leadEntry.AddressUUID
      if (leadEntry.Units.length > 0) {
        const unitLeaseInfo: string[] = []

        leadEntry.Units.forEach((unit) => {
          if (addressesAndUnitsWithLeaseInfoData.includes(unit.UUID)) {
            unitLeaseInfo.push(unit.UUID)
          }
        })
        AddressLeaseInfo[addressUuid] = unitLeaseInfo
      } else if (addressesAndUnitsWithLeaseInfoData.includes(addressUuid)) {
        AddressLeaseInfo[addressUuid] = [addressUuid]
      } else {
        AddressLeaseInfo[addressUuid] = []
      }
    })

    try {
      await createTask({
        variables: {
          createTaskInput: {
            type: 'create_order',
            requestData: JSON.stringify({
              request_detail: JSON.stringify({
                orderName: result.orderName,
                addressUuidsWithLease: AddressLeaseInfo,
                lead_uuids: selectedLeadUuids,
                offerPrice: result.offerPrice,
                capex: 0, // Default capex to 0 unless added back to form
                targetCloseDate: result.targetCloseDate,
              }),
            }),
          },
        },
      })
      notify('Successfully requested Contribution Agreement.', 'success')
      closeModal()
      navigate(NEW_CONTRIBUTIONS_URL)
    } catch (e) {
      notify('Failed to request Contribution Agreement', 'error')
    }
  }

  return (
    <>
      <Button
        data-cy="requestContributionAgreementLeadAction"
        sx={{ width: '100%', justifyContent: 'flex-start' }}
        onClick={() => {
          openModal()
        }}
      >
        Request Contribution Agreement
      </Button>
      <Modal open={open} onClose={closeModal}>
        <ModalWrapper>
          <SectionTitleContainer>
            <Typography variant="h2">New Contribution</Typography>
          </SectionTitleContainer>
          <SectionTitleContainer>
            <Typography variant="h2">Selected Leads</Typography>
          </SectionTitleContainer>
          {leadsLoading ? (
            <LoadingCard text="Loading..." />
          ) : (
            <DataTable
              title=""
              data={selectedLeads}
              columns={leadColumns}
              options={selectedLeadTableOptions as any}
            />
          )}
          <SectionTitleContainer>
            <Typography variant="h2">Search and Add Leads</Typography>
          </SectionTitleContainer>
          <SearchWrapper>
            <SearchField
              variant="outlined"
              placeholder="Search leads"
              defaultValue={querySearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={debounceSetSearchStringToSearchFieldInput}
              data-cy="leadSearchField"
            />
          </SearchWrapper>
          {leadsLoading ? (
            <LoadingCard text="Loading..." />
          ) : (
            <DataTable
              title=""
              data={formattedLeadsData}
              columns={leadColumns}
              options={leadTableOptions as any}
            />
          )}
          <Box sx={{ display: 'flex', width: '100%', marginBottom: '1rem' }}>
            <ThemeProvider theme={flockTheme}>
              <Grid container>
                <GridForm
                  onSubmit={onSubmit}
                  inputConfigs={inputConfigs}
                  loading={leadsLoading}
                  ctaText="Create"
                  gridProps={{
                    spacing: 3,
                  }}
                  ctaBoxProps={{
                    pb: '32px',
                  }}
                />
              </Grid>
            </ThemeProvider>
          </Box>
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default CustomerRequestContributionAgreementModal
