import { useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import {
  AdminCreateLegalEntityForOrderDocument,
  AdminSearchLegalEntitiesDocument,
  Core_CreateLegalEntityRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { AddressData, InputType, LibraryThemeProvider } from '@flock/shared-ui'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { GOOGLE_MAPS_API_KEY } from '../../../constants'
import GridFormModal from '../../shared/GridFormModal'

type CreateLegalEntityForOrderParams = {
  investorAccountUuid: string
  name: string
  type: string
  email: string
  phoneNumber: string
  dateJoined: string
  address: AddressData
  cashFlowAllotment: string
  holdingExpiryDate: string
}

const CreateLegalEntityModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { notify } = useSnackbar()
  const [createLegalEntityForOrder, { loading }] = useMutation(
    AdminCreateLegalEntityForOrderDocument
  )

  const onSubmit = async (
    createInvestorLegalEntityParams: CreateLegalEntityForOrderParams
  ) => {
    const {
      name,
      type,
      email,
      phoneNumber,
      dateJoined,
      address,
      cashFlowAllotment,
      holdingExpiryDate,
    } = createInvestorLegalEntityParams
    const cashFlowMax = parseFloat(cashFlowAllotment)

    const input: Core_CreateLegalEntityRequestInput = {
      name,
      type,
      email,
      phoneNumber: phoneNumber.replace(/[^0-9]/g, ''),
      dateJoined: dateJoined || '',
      primaryMailingAddress: address.formattedAddress,
      cashFlowAllotment: cashFlowMax,
      holdingExpiryDate: holdingExpiryDate || '',
    }

    try {
      await createLegalEntityForOrder({
        variables: {
          input,
        },
        refetchQueries: [AdminSearchLegalEntitiesDocument],
      })
      notify('Successfully created new legal entity.', 'success')
      setIsOpen(false)
    } catch (e) {
      notify('Failed to create new legal entity for investor.', 'error')
    }
  }
  return (
    <>
      <Button
        variant="contained"
        onClick={() => setIsOpen(true)}
        sx={{ marginRight: '0.5rem' }}
        size="smallForm"
      >
        Create
      </Button>
      <LibraryThemeProvider>
        <GridFormModal
          open={isOpen}
          loading={loading}
          title="Create Legal Entity"
          onClose={() => setIsOpen(false)}
          onSubmit={onSubmit}
          inputConfigs={[
            {
              name: 'name',
              type: InputType.Text,
              required: true,
              props: {
                label: 'Legal Entity Name',
                type: 'text',
                fullWidth: true,
              },
            },
            {
              name: 'type',
              type: InputType.Dropdown,
              required: true,
              props: {
                label: 'Type',
                fullWidth: true,
                options: [
                  {
                    label: 'Individual',
                    value: 'individual',
                  },
                  {
                    label: 'LLC',
                    value: 'llc',
                  },
                  {
                    label: 'Corporation',
                    value: 'corporation',
                  },
                  {
                    label: 'Trust',
                    value: 'trust',
                  },
                ],
              },
            },
            {
              name: 'dateJoined',
              type: InputType.DatePicker,
              props: {
                label: 'Date Joined',
                defaultValue: new Date(),
                fullWidth: true,
              },
            },
            {
              name: 'email',
              type: InputType.Text,
              required: true,
              props: {
                fullWidth: true,
                format: 'email',
                label: 'Email',
              },
            },
            {
              name: 'phoneNumber',
              type: InputType.Text,
              required: true,
              props: {
                fullWidth: true,
                format: 'phone',
                label: 'Phone Number',
              },
            },
            {
              name: 'address',
              type: InputType.Address,
              required: true,
              props: {
                googleMapsApiKey: GOOGLE_MAPS_API_KEY,
                label: 'Please enter the address',
                fullWidth: true,
              },
            },
            {
              name: 'cashFlowAllotment',
              type: InputType.Text,
              required: true,
              props: {
                label: 'Cash Flow Allotment',
                type: 'number',
                fullWidth: true,
              },
            },
            {
              name: 'holdingExpiryDate',
              type: InputType.DatePicker,
              props: {
                label: 'Lockup Expiration',
                defaultValue: new Date(),
                fullWidth: true,
              },
            },
          ]}
        />
      </LibraryThemeProvider>
    </>
  )
}

export default CreateLegalEntityModal
