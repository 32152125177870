import React, { useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import {
  AdminCreateOrderV3Document,
  AdminCreateOrderV3Mutation,
  AdminCreateTestContributionAgreementDocument,
  AdminSearchLegalEntitiesDocument,
  Core_LegalEntity,
  Core_CreateOrderV3Request_CreateOrderV3LegalEntityParamsInput,
  Core_CreateOrderV3Request_CreateOrderV3PropertyContributionParamsInput,
  Core_CreateOrderV3Request_CreateOrderV3SellerParamsInput,
  Core_CreateOrderV3Request_CreateOrderV3SellerParams_CreateOrderV3AuthorizedSignerParamsInput,
  AdminFindOrCreateAddressDocument,
  AdminCreateMultiunitAddressDocument,
  AdminGetAddressesForScenarioDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { flockColors } from '@flock/flock-component-library'

import { AnyInputConfig, InputType, useSnackbar } from '@flock/shared-ui'
import { VALID_STATES } from '@flock/utils'
import { useParams } from '@reach/router'
import { styled, TableCell, TableRow } from '@mui/material'
import { Add, Remove } from '@mui/icons-material'
import {
  CreateSalesforceOrderPagePresentationalProps,
  AddressToConfirm,
  FlockUnit,
  ProcessedSalesforceAddress,
} from './createSalesforceOrderPageTypes'

const getAddressParts = (formattedAddress: string) => {
  const parts = formattedAddress.split(',')
  const address = parts[0]
  const cityStateZip = parts.slice(1).join(',').trim()
  return { address, cityStateZip }
}

export const selectedLegalEntityColumns = [
  {
    name: 'value',
    options: { display: false },
  },
  {
    name: '',
  },
  {
    name: 'label',
    label: 'Legal Entity Name',
    options: { sortThirdClickReset: true },
  },
]

export const legalEntityColumns = [
  {
    name: 'uuid',
    options: { display: false },
  },
  {
    name: '',
  },
  {
    name: 'name',
    label: 'Legal Entity Name',
    options: { sortThirdClickReset: true },
  },
]

const RemoveTableCell = styled(TableCell)({
  backgroundColor: flockColors.red,
  opacity: '75%',
  width: '1.5rem',
  textAlign: 'center',
  color: flockColors.white,
})
const AddTableCell = styled(TableCell)({
  opacity: '75%',
  width: '1.5rem',
  textAlign: 'center',
  color: flockColors.white,
})

export const FIND_OR_CREATE_ADDRESS = gql`
  mutation AdminFindOrCreateAddress(
    $input: Core_FindOrCreateAddressRequestInput!
  ) {
    findOrCreateAddress(input: $input) {
      address {
        uuid
        formattedAddress
        units {
          uuid
          unit
          formattedAddress
        }
      }
    }
  }
`

export const CREATE_MULTIUNIT_ADDRESS = gql`
  mutation AdminCreateMultiunitAddress(
    $input: Core_CreateMultiunitAddressRequestInput!
  ) {
    createMultiunitAddress(input: $input) {
      _empty
    }
  }
`

export const GET_ADDRESSES_FOR_SCENARIO = gql`
  query AdminGetAddressesForScenario(
    $input: Core_GetAddressesForScenarioRequestInput!
  ) {
    getAddressesForScenario(input: $input) {
      addresses {
        id
        formattedAddress
        hasTenant
        units {
          id
          formattedAddress
          hasTenant
        }
      }
    }
  }
`

const useCreateSalesforceOrderPage: () => CreateSalesforceOrderPagePresentationalProps =
  () => {
    // Hooks
    const { notify } = useSnackbar()
    const { scenarioId } = useParams()

    // State
    // TODO: Readd the setter when the data is fetched from Salesforce
    const [addressesToConfirm, setAddressesToConfirm] = useState<
      AddressToConfirm[]
    >([])

    const [createOrderV3, { loading: createOrderLoading }] = useMutation(
      AdminCreateOrderV3Document
    )
    const [createTestCA, { loading: createTestCALoading }] = useMutation(
      AdminCreateTestContributionAgreementDocument
    )
    const [orderConfirmationOpen, setOrderConfirmationOpen] = useState(false)
    const [order, setOrder] = useState({} as AdminCreateOrderV3Mutation)
    const [selectedLegalEntities, setSelectedLegalEntities] = useState<
      { label: string; value: string }[]
    >([])
    const [addressesLoading, setAddressesLoading] = useState(false)
    const [isTestDownload, setIsTestDownload] = useState(true)

    // Queries
    const { loading: legalEntitiesLoading, data: legalEntitiesData } = useQuery(
      AdminSearchLegalEntitiesDocument,
      {
        variables: {
          searchLegalEntitiesInput: {},
        },
        onError: () => {
          notify('Failed to get investor account data', 'error')
        },
      }
    )

    const { refetch: getAddressesForScenario } = useQuery(
      AdminGetAddressesForScenarioDocument,
      {
        skip: true,
      }
    )

    // Mutations
    const [findOrCreateAddress] = useMutation(AdminFindOrCreateAddressDocument)
    const [createMultiunitAddress] = useMutation(
      AdminCreateMultiunitAddressDocument
    )

    // Functions
    const confirmAddress = (idx: number) => {
      const updatedAddressesToConfirm = [...addressesToConfirm]
      updatedAddressesToConfirm[idx].confirmed = true
      setAddressesToConfirm(updatedAddressesToConfirm)
    }

    const closeOrderConfirmation = () => {
      setOrderConfirmationOpen(false)
    }

    const removeLegalEntity = (legalEntityUuid: string) => {
      const selectedLegalEntitiesSet = new Set(selectedLegalEntities)
      Array.from(selectedLegalEntitiesSet).forEach((legalEntity) => {
        if (legalEntity.value === legalEntityUuid) {
          selectedLegalEntitiesSet.delete(legalEntity)
        }
      })

      const updatedSelectedLegalEntities = Array.from(selectedLegalEntitiesSet)
      setSelectedLegalEntities(updatedSelectedLegalEntities)
    }
    const addLegalEntity = (legalEntityToAdd: {
      label: string
      value: string
    }) => {
      if (
        !selectedLegalEntities.some((l) => l.value === legalEntityToAdd.value)
      ) {
        const selectedLegalEntitiesSet = new Set(selectedLegalEntities)
        selectedLegalEntitiesSet.add(legalEntityToAdd)
        const updatedSelectedLegalEntities = Array.from(
          selectedLegalEntitiesSet
        )
        setSelectedLegalEntities(updatedSelectedLegalEntities)
      }
    }

    const onSubmit = async (result: any) => {
      // If any address is not confirmed, do not submit the order
      if (addressesToConfirm.some((address) => !address.confirmed)) {
        notify(
          'Please confirm all addresses before submitting the order. If an address is incorrect, please notify the engineering team.',
          'error'
        )
        return
      }

      // iterate through result.legalEntityParams and create a list of legal entities
      const legalEntitiesOrderParams: Core_CreateOrderV3Request_CreateOrderV3LegalEntityParamsInput[] =
        []

      result.legalEntities.forEach((legalEntity: any) => {
        legalEntitiesOrderParams.push({
          legalEntityUuid: legalEntity.legalEntityUUID,
          dealEquityOwnershipAmount: Number.parseFloat(
            legalEntity.dealEquityOwnershipAmount
          ),
        })
      })

      const sellerEntitiesOrderParams: Core_CreateOrderV3Request_CreateOrderV3SellerParamsInput[] =
        []

      result.sellerEntities.forEach((sellerEntity: any) => {
        const sellerEntityAuthorizedSigners: Core_CreateOrderV3Request_CreateOrderV3SellerParams_CreateOrderV3AuthorizedSignerParamsInput[] =
          []
        sellerEntity.authorizedSigners.forEach((authorizedSigner: any) => {
          sellerEntityAuthorizedSigners.push({
            name: authorizedSigner.authorizedSignerName,
            email: authorizedSigner.authorizedSignerEmail,
            type: authorizedSigner.authorizedSignerType,
            legalEntityUuid: authorizedSigner.authorizedSignerLegalEntityUUID,
            relationToEntity: authorizedSigner.authorizedSignerRelationToEntity,
            skipCaTask: authorizedSigner.authorizedSignerSkipCATask,
            skipPersonalInformationTask:
              authorizedSigner.authorizedSignerSkipPersonalInfoTask,
            isPrimary: authorizedSigner.authorizedSignerIsPrimary || false,
          })
        })

        sellerEntitiesOrderParams.push({
          legalEntityUuid: sellerEntity.sellerEntityUUID,
          type: sellerEntity.sellerEntityType,
          name: sellerEntity.sellerEntityName,
          legalEntityState: sellerEntity.sellerEntityState,
          authorizedSigners: sellerEntityAuthorizedSigners,
        })
      })

      const propertyContributionsOrderParams: Core_CreateOrderV3Request_CreateOrderV3PropertyContributionParamsInput[] =
        []
      addressesToConfirm.forEach((addressToConfirm: AddressToConfirm) => {
        const addressUuidsWithLease: string[] = []
        if (
          addressToConfirm.flockUnits.length === 0 &&
          addressToConfirm.hasLease[0]
        ) {
          addressUuidsWithLease.push(addressToConfirm.parentFlockAddressUuid)
        } else {
          for (let i = 0; i < addressToConfirm.flockUnits.length; i += 1) {
            if (addressToConfirm.hasLease[i]) {
              addressUuidsWithLease.push(
                addressToConfirm.flockUnits[i].addressUuid
              )
            }
          }
        }

        const propertyContribution: Core_CreateOrderV3Request_CreateOrderV3PropertyContributionParamsInput =
          {
            addressUuid: addressToConfirm.parentFlockAddressUuid,
            salesforceAddressId: addressToConfirm.salesforceAddressId,
            addressUuidsWithLease,
            titleCompany: result.titleCompany,
          }
        propertyContributionsOrderParams.push(propertyContribution)
      })

      try {
        const newOrder = await createOrderV3({
          variables: {
            createOrderV3Input: {
              offerPrice: Number.parseFloat(result.offerPrice),
              capitalExpenditure: Number.parseFloat(result.capex),
              targetCloseDate: result.targetCloseDate.toISOString(),
              skipPropertyQuestionnaire: result.skipPropertyQuestionnaireTask,
              suppressTaxInformation: result.suppressTaxInformation,
              sellers: sellerEntitiesOrderParams,
              legalEntities: legalEntitiesOrderParams,
              propertyContributions: propertyContributionsOrderParams,
              onboardingFee: result.onboardingFee
                ? Number.parseFloat(result.onboardingFee)
                : undefined,
              flexibleCash: result.isFlexibleCash,
            },
          },
        })
        setOrder(newOrder.data!)
        setOrderConfirmationOpen(true)
        notify('Successfully Created Order', 'success')
      } catch (e) {
        notify(`Failed to create Order: ${e}.`, 'error')
      }
    }

    const testCA = async (result: any) => {
      // If any address is not confirmed, do not download test CA
      if (addressesToConfirm.some((address) => !address.confirmed)) {
        notify(
          'Please confirm all addresses before generating a test CA. If an address is incorrect, please notify the engineering team.',
          'error'
        )
        return
      }

      // iterate through result.legalEntityParams and create a list of legal entities
      const legalEntitiesOrderParams: Core_CreateOrderV3Request_CreateOrderV3LegalEntityParamsInput[] =
        []

      result.legalEntities.forEach((legalEntity: any) => {
        legalEntitiesOrderParams.push({
          legalEntityUuid: legalEntity.legalEntityUUID,
          dealEquityOwnershipAmount: Number.parseFloat(
            legalEntity.dealEquityOwnershipAmount
          ),
        })
      })

      const sellerEntitiesOrderParams: Core_CreateOrderV3Request_CreateOrderV3SellerParamsInput[] =
        []

      result.sellerEntities.forEach((sellerEntity: any) => {
        const sellerEntityAuthorizedSigners: Core_CreateOrderV3Request_CreateOrderV3SellerParams_CreateOrderV3AuthorizedSignerParamsInput[] =
          []
        sellerEntity.authorizedSigners.forEach((authorizedSigner: any) => {
          sellerEntityAuthorizedSigners.push({
            name: authorizedSigner.authorizedSignerName,
            email: authorizedSigner.authorizedSignerEmail,
            type: authorizedSigner.authorizedSignerType,
            legalEntityUuid: authorizedSigner.authorizedSignerLegalEntityUUID,
            relationToEntity: authorizedSigner.authorizedSignerRelationToEntity,
            skipCaTask: authorizedSigner.authorizedSignerSkipCATask,
            skipPersonalInformationTask:
              authorizedSigner.authorizedSignerSkipPersonalInfoTask,
            isPrimary: authorizedSigner.authorizedSignerIsPrimary || false,
          })
        })

        sellerEntitiesOrderParams.push({
          legalEntityUuid: sellerEntity.sellerEntityUUID,
          type: sellerEntity.sellerEntityType,
          name: sellerEntity.sellerEntityName,
          legalEntityState: sellerEntity.sellerEntityState,
          authorizedSigners: sellerEntityAuthorizedSigners,
        })
      })

      const propertyContributionsOrderParams: Core_CreateOrderV3Request_CreateOrderV3PropertyContributionParamsInput[] =
        []
      addressesToConfirm.forEach((addressToConfirm: AddressToConfirm) => {
        const addressUuidsWithLease: string[] = []
        if (
          addressToConfirm.flockUnits.length === 0 &&
          addressToConfirm.hasLease[0]
        ) {
          addressUuidsWithLease.push(addressToConfirm.parentFlockAddressUuid)
        } else {
          for (let i = 0; i < addressToConfirm.flockUnits.length; i += 1) {
            if (addressToConfirm.hasLease[i]) {
              addressUuidsWithLease.push(
                addressToConfirm.flockUnits[i].addressUuid
              )
            }
          }
        }

        const propertyContribution: Core_CreateOrderV3Request_CreateOrderV3PropertyContributionParamsInput =
          {
            addressUuid: addressToConfirm.parentFlockAddressUuid,
            salesforceAddressId: addressToConfirm.salesforceAddressId,
            addressUuidsWithLease,
            titleCompany: result.titleCompany,
          }
        propertyContributionsOrderParams.push(propertyContribution)
      })

      try {
        const response = await createTestCA({
          variables: {
            createTestContributionAgreementInput: {
              offerPrice: Number.parseFloat(result.offerPrice),
              capitalExpenditure: Number.parseFloat(result.capex),
              targetCloseDate: result.targetCloseDate.toISOString(),
              skipPropertyQuestionnaire: result.skipPropertyQuestionnaireTask,
              suppressTaxInformation: result.suppressTaxInformation,
              sellers: sellerEntitiesOrderParams,
              legalEntities: legalEntitiesOrderParams,
              propertyContributions: propertyContributionsOrderParams,
              onboardingFee: result.onboardingFee
                ? Number.parseFloat(result.onboardingFee)
                : undefined,
              flexibleCash: result.isFlexibleCash,
            },
          },
        })
        window.open(
          response.data?.createTestContributionAgreement
            ?.presignedUrl as string,
          '_blank'
        )
        notify('Successfully Created Test CA', 'success')
      } catch (e) {
        notify(`Failed to create test CA: ${e}.`, 'error')
      }
    }

    const fetchOrderData = async () => {
      setAddressesLoading(true)
      try {
        const addressData = await getAddressesForScenario({
          input: {
            scenarioId,
          },
        })

        // TODO: Potentially ensure unit names are correct from the backend
        // Process the unit names out of the formatted address

        const salesforceAddresses =
          addressData.data.getAddressesForScenario?.addresses.map(
            (sfAddress) => {
              const { formattedAddress, units, hasTenant } = sfAddress
              const processedAddress: ProcessedSalesforceAddress = {
                salesforceAddressId: '',
                formattedAddress: '',
                units: [],
                hasLease: [],
              }
              processedAddress.salesforceAddressId = sfAddress.id as string
              processedAddress.formattedAddress = formattedAddress as string

              const splitAddress = formattedAddress!.split(',')
              const firstAddressPart = splitAddress[0]
              const lastAddressPart = splitAddress.slice(1).join(',')

              const preProcessedUnits = units?.slice() || []
              preProcessedUnits?.sort((a, b) => {
                if (a!.formattedAddress! < b!.formattedAddress!) {
                  return -1
                }
                if (a!.formattedAddress! > b!.formattedAddress!) {
                  return 1
                }
                return 0
              })

              if (preProcessedUnits?.length && preProcessedUnits?.length > 0) {
                for (let i = 0; i < preProcessedUnits!.length; i += 1) {
                  const currentUnit = preProcessedUnits![i]
                  if (currentUnit?.hasTenant) {
                    processedAddress.hasLease.push(true)
                  } else {
                    processedAddress.hasLease.push(false)
                  }

                  // Extract the unit from the string
                  const unitName = currentUnit?.formattedAddress
                    ?.replace(firstAddressPart, '')
                    .replace(lastAddressPart, '')
                    .replace(/,/g, '')
                    .trim()

                  processedAddress.units.push(unitName as string)
                }
              } else {
                let hasLease = false
                if (hasTenant) {
                  hasLease = true
                }
                processedAddress.hasLease = [hasLease]
              }
              return processedAddress
            }
          )

        // For each address, find or create the address in Flock.
        // If the address is a multiunit address, find or create the address in Flock and check if there are units on the address.
        // If there are no units on the Flock address, create the units on the address.
        const newAddressesToConfirm: AddressToConfirm[] = []
        for (let i = 0; i < salesforceAddresses!.length; i += 1) {
          const salesforceAddress = salesforceAddresses![i]
          let findOrCreateAddressResponse = await findOrCreateAddress({
            variables: {
              input: {
                addressString: salesforceAddress.formattedAddress,
              },
            },
          })
          const flockAddressUuid =
            findOrCreateAddressResponse?.data?.findOrCreateAddress?.address
              ?.uuid

          const flockFormattedAddress =
            findOrCreateAddressResponse?.data?.findOrCreateAddress?.address
              ?.formattedAddress

          const existingUnits =
            findOrCreateAddressResponse?.data?.findOrCreateAddress?.address
              ?.units || []

          let flockFormattedUnits: FlockUnit[] = []
          if (
            salesforceAddress.units &&
            existingUnits.length !== salesforceAddress.units.length
          ) {
            await createMultiunitAddress({
              variables: {
                input: {
                  addressUuid: flockAddressUuid as string,
                  units: salesforceAddress.units,
                },
              },
            })

            // Refetch the root address and units
            findOrCreateAddressResponse = await findOrCreateAddress({
              variables: {
                input: {
                  addressString: salesforceAddress.formattedAddress,
                },
              },
            })
          }
          flockFormattedUnits =
            findOrCreateAddressResponse?.data?.findOrCreateAddress?.address?.units?.map(
              (unit: any) => ({
                name: unit.unit,
                addressUuid: unit.uuid,
              })
            ) || []

          flockFormattedUnits.sort((a, b) => {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })

          const salesforceAddressParts = getAddressParts(
            salesforceAddress.formattedAddress
          )

          const flockAddressParts = getAddressParts(
            flockFormattedAddress as string
          )

          newAddressesToConfirm.push({
            parentFlockAddressUuid: flockAddressUuid as string,
            salesforceAddressId: salesforceAddress.salesforceAddressId,
            salesforceFormattedAddressStreet: salesforceAddressParts.address,
            salesforceFormattedAddressCityStateZip:
              salesforceAddressParts.cityStateZip,
            salesforceUnits: salesforceAddress.units || [],
            flockFormattedAddressStreet: flockAddressParts.address,
            flockFormattedAddressCityStateZip: flockAddressParts.cityStateZip,
            flockUnits: flockFormattedUnits,
            hasLease: salesforceAddress.hasLease,
            confirmed: false,
          })
        }

        setAddressesToConfirm(newAddressesToConfirm)
      } catch (e) {
        notify(`Failed to fetch order data: ${e.message}`, 'error')
        console.log(e)
      }
      setAddressesLoading(false)
    }

    useEffect(() => {
      fetchOrderData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const legalEntities =
      (legalEntitiesData?.searchLegalEntities
        ?.legalEntities as Core_LegalEntity[]) || []

    const legalEntityNumberTolegalEntity: {
      [key: number]: Core_LegalEntity
    } = {}

    // iterate through investments and create a map of investment number to investment uuid
    legalEntities?.forEach((legalEntity: Core_LegalEntity, index: number) => {
      legalEntityNumberTolegalEntity[index] = legalEntity
    })

    const legalEntityTableOptions = {
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
      search: true,
      sort: true,
      selectableRows: false,
      responsive: 'standard',
      elevation: 0,
      customRowRender: (data: any, dataIndex: number) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention, @typescript-eslint/no-shadow
        const [_1, _2, formattedAddress] = data
        const legalEntity = legalEntityNumberTolegalEntity[dataIndex]
        const legalEntityEntry = {
          label: legalEntity.name!,
          value: legalEntity.uuid!,
        }
        const isSelected = selectedLegalEntities.some(
          (l) => l.value === legalEntity.uuid
        )

        return (
          <TableRow>
            <AddTableCell
              onClick={() => {
                addLegalEntity(legalEntityEntry)
              }}
              style={{
                cursor: isSelected ? 'default' : 'pointer',
                backgroundColor: isSelected
                  ? flockColors.darkGray
                  : flockColors.green,
              }}
            >
              <Add />
            </AddTableCell>

            <TableCell>{formattedAddress}</TableCell>
          </TableRow>
        )
      },
      searchOpen: true,
    }

    const selectedLegalEntityTableOptions = {
      selectableRows: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
      search: true,
      responsive: 'standard',
      elevation: 0,
      customRowRender: (data: any) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars, @typescript-eslint/no-shadow
        const [uuid, _, formattedAddress] = data
        return (
          <TableRow>
            <RemoveTableCell
              onClick={() => {
                removeLegalEntity(uuid)
              }}
              style={{ cursor: 'pointer' }}
            >
              <Remove />
            </RemoveTableCell>

            <TableCell>{formattedAddress}</TableCell>
          </TableRow>
        )
      },
    }

    const inputConfigs: AnyInputConfig[] = [
      {
        name: 'offerPrice',
        required: true,
        type: InputType.Text,
        gridItemProps: {
          sm: 4,
        },
        props: {
          label: 'Total Purchase Price',
          format: 'dollarsAndCents',
          placeholder: '$',
        },
      },
      {
        name: 'onboardingFee',
        type: InputType.Text,
        gridItemProps: {
          sm: 4,
        },
        props: {
          label: 'Onboarding Fee %',
          format: 'number',
        },
      },
      {
        name: 'isFlexibleCash',
        type: InputType.Checkbox,
        gridItemProps: {
          sm: 4,
        },
        props: {
          label: 'Alternate Cash/Hold (CA Form B)',
        },
      },
      {
        name: 'capex',
        required: true,
        type: InputType.Text,
        gridItemProps: {
          sm: 4,
        },
        props: {
          label: 'Capex',
          format: 'dollarsAndCents',
          placeholder: '$',
        },
      },
      {
        name: 'targetCloseDate',
        required: true,
        type: InputType.DatePicker,
        gridItemProps: {
          sm: 4,
        },
        props: {
          label: 'Target Close Date',
        },
      },
      {
        name: 'placeholder',
        type: InputType.CustomComponent,
        gridItemProps: {
          sm: 4,
        },
        props: {},
      },
      {
        name: 'skipPropertyQuestionnaireTask',
        type: InputType.Checkbox,
        gridItemProps: {
          sm: 4,
        },
        props: {
          label: 'Skip Property Questionnaire Task',
        },
      },
      {
        name: 'suppressTaxInformation',
        type: InputType.Checkbox,
        gridItemProps: {
          sm: 4,
        },
        props: {
          label: 'Suppress Tax Information',
        },
      },
      {
        name: 'titleCompany',
        type: InputType.Dropdown,
        required: true,
        props: {
          label: 'Title Company',
          options: [
            {
              label: 'First American Title Insurance Company',
              value: 'First American Title Insurance Company',
            },
            {
              label: 'Blueprint Title Company',
              value: 'Blueprint Title Company',
            },
            {
              label: 'Prominent Escrow Services, Inc.',
              value: 'Prominent Escrow Services, Inc.',
            },
            {
              label: 'Endpoint Title, LLC',
              value: 'Endpoint Title, LLC',
            },
            {
              label: 'TitleVest Agency, LLC',
              value: 'TitleVest Agency, LLC',
            },
            {
              label: 'VanderVeur & Page',
              value: 'VanderVeur & Page',
            },
            {
              label: 'Cook & James LLC on behalf of Spruce Land Services LLC',
              value: 'Cook & James LLC on behalf of Spruce Land Services LLC',
            },
            {
              label: 'Hankin & Pack PLLC',
              value: 'Hankin & Pack PLLC',
            },
            {
              label: 'Hankin & Pack PLLC via Blueprint Title Company',
              value: 'Hankin & Pack PLLC via Blueprint Title Company',
            },
          ],
        },
      },
      {
        name: 'sellerEntities',
        required: true,
        type: InputType.Multiform,
        props: {
          title: 'Seller Entities',
          minForms: 1,
          incrementText: '+ Add Seller Entities',
          decrementText: '- Remove Seller Entities',
          inputConfigs: [
            {
              name: 'sellerEntityName',
              required: true,
              type: InputType.Text,
              props: {
                label: 'Seller Entity name',
              },
            },
            {
              name: 'sellerEntityUUID',
              required: true,
              type: InputType.Dropdown,
              props: {
                label: 'Legal Entity <> Seller Entity Association',
                options: selectedLegalEntities,
              },
            },
            {
              name: 'sellerEntityType',
              required: true,
              type: InputType.Dropdown,
              gridItemProps: {
                sm: 6,
              },
              props: {
                label: 'Seller Entity Type',
                options: [
                  {
                    label: 'Individual',
                    value: 'individual',
                  },
                  {
                    label: 'LLC',
                    value: 'llc',
                  },
                  {
                    label: 'Corporation',
                    value: 'corporation',
                  },
                  {
                    label: 'Trust',
                    value: 'trust',
                  },
                ],
              },
            },
            {
              name: 'sellerEntityState',
              type: InputType.Dropdown,
              required: true,
              gridItemProps: {
                sm: 6,
              },
              props: {
                label: 'Seller Entity State',
                options: VALID_STATES.map((state) => ({
                  label: state,
                  value: state,
                })),
              },
            },
            {
              name: 'authorizedSigners',
              required: true,
              type: InputType.Multiform,
              props: {
                title: 'Authorized Signers',
                minForms: 1,
                incrementText: '+ Add Authorized Signers',
                decrementText: '- Remove Authorized Signers',
                inputConfigs: [
                  {
                    name: 'authorizedSignerName',
                    type: InputType.Text,
                    required: true,
                    props: {
                      label: 'Name',
                      format: 'fullName',
                      placeholder: 'First and last name',
                    },
                    gridItemProps: {
                      sm: 6,
                    },
                  },
                  {
                    name: 'authorizedSignerEmail',
                    type: InputType.Text,
                    required: true,
                    props: {
                      label: 'Email',
                      format: 'email',
                    },
                    gridItemProps: {
                      sm: 6,
                    },
                  },
                  {
                    name: 'authorizedSignerRelationToEntity',
                    type: InputType.Text,
                    required: true,
                    props: {
                      label: 'Relation to Entity',
                    },
                    gridItemProps: {
                      sm: 6,
                    },
                  },
                  {
                    name: 'authorizedSignerType',
                    required: true,
                    type: InputType.Dropdown,
                    gridItemProps: {
                      sm: 6,
                    },
                    props: {
                      label: 'Authorized Signer Type',
                      options: [
                        {
                          label: 'Individual',
                          value: 'individual',
                        },
                        {
                          label: 'LLC',
                          value: 'llc',
                        },
                        {
                          label: 'Corporation',
                          value: 'corporation',
                        },
                        {
                          label: 'Trust',
                          value: 'trust',
                        },
                      ],
                    },
                  },
                  {
                    name: 'authorizedSignerLegalEntityUUID',
                    required: true,
                    type: InputType.Dropdown,
                    gridItemProps: {
                      sm: 6,
                    },
                    props: {
                      label: 'Legal Entity <> Authorized Signer Association',
                      options: selectedLegalEntities,
                    },
                  },
                  {
                    name: 'authorizedSignerSkipCATask',
                    type: InputType.Checkbox,
                    gridItemProps: {
                      sm: 2,
                    },
                    props: {
                      label: 'Skip CA Task',
                    },
                  },
                  {
                    name: 'authorizedSignerSkipPersonalInfoTask',
                    type: InputType.Checkbox,
                    gridItemProps: {
                      sm: 2,
                    },
                    props: {
                      label: 'Skip Personal Info Task',
                    },
                  },
                  {
                    name: 'authorizedSignerIsPrimary',
                    type: InputType.Checkbox,
                    gridItemProps: {
                      sm: 2,
                    },
                    props: {
                      label: 'Is Primary Signer',
                    },
                  },
                ],
              },
            },
          ],
        },
      },

      {
        name: 'legalEntities',
        type: InputType.Multiform,
        props: {
          title: 'Legal Entities',
          minForms: 1,
          incrementText: '+ Add LE',
          decrementText: '- Remove LE',
          inputConfigs: [
            {
              name: 'legalEntityUUID',
              required: true,
              type: InputType.Dropdown,
              gridItemProps: {
                sm: 6,
              },
              props: {
                label: 'Legal Entity',
                options: selectedLegalEntities,
              },
            },
            {
              name: 'dealEquityOwnershipAmount',
              type: InputType.Text,
              required: true,
              props: {
                label: 'Deal Ownership Amount',
                format: 'dollarsAndCents',
                placeholder: '$',
              },
              gridItemProps: {
                sm: 6,
              },
            },
          ],
        },
      },
    ]

    const loading = legalEntitiesLoading || addressesLoading

    return {
      notify,
      order,
      scenarioId,
      legalEntities,
      selectedLegalEntities,
      loading,
      createOrderLoading,
      createTestCALoading,
      orderConfirmationOpen,
      legalEntityTableOptions,
      selectedLegalEntityTableOptions,
      inputConfigs,
      onSubmit,
      testCA,
      closeOrderConfirmation,
      confirmAddress,
      addressesToConfirm,
      isTestDownload,
      setIsTestDownload,
    }
  }

export default useCreateSalesforceOrderPage
