import React from 'react'
import { Typography, Box, CircularProgress, Grid } from '@mui/material'
import { GridForm } from '@flock/shared-ui'

import RejectValuationModal from '../RejectValuationModal/RejectValuationModal'
import ValuationHistoryModal from '../ValuationHistoryModal/ValuationHistoryModal'
import useMultiFamilyComputeAddressValuationTab from './useMultiFamilyComputeAddressValuationTab'
import { MultiFamilyComputeAddressValuationTabProps } from './multiFamilyComputeAddressValuationTabTypes'
import ValuationSummary from './ValuationSummary/ValuationSummary'

const MultiFamilyComputeAddressValuationTab = (
  props: MultiFamilyComputeAddressValuationTabProps
) => {
  const { valuationHistory, valuationHistoryLoading, addressData } = props
  const {
    computeValuationFormFormProps,
    computeValuationFormInputConfigs,
    loading,
    operatorUuid,
    setRejectValuationModalOpen,
    rejectValuationModalOpen,
    setValuationHistoryModalOpen,
    valuationHistoryModalOpen,
    submitComputeValuation,
    computedValuationResult,
    valuationInputs,
    validateFormattedFormData,
    canRejectValuation,
    canSubmitValuation,
    // selfReportedValue,
    openValuationHistoryModal,
    openRejectValuationModal,
    numUnits,
    computeValuationLoading,
    addressId,
  } = useMultiFamilyComputeAddressValuationTab(props)

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size="100px" />
      </Box>
    )
  } else {
    return (
      <>
        <Typography variant="h2">Multi Family Valuation</Typography>
        {numUnits < 2 ? (
          <Typography variant="h3">
            This property doesn&apos;t have multiple units. Please use the
            Single Family Valuation tab. Or create a new lead with the correct
            number of units data which will overwrite the existing address
            details for this address.
          </Typography>
        ) : (
          <>
            <RejectValuationModal
              addressId={addressId}
              operatorUuid={operatorUuid}
              open={rejectValuationModalOpen}
              onClose={() => setRejectValuationModalOpen(false)}
              canRejectValuation={canRejectValuation}
              valuationUuid={computedValuationResult?.uuid}
            />
            <ValuationHistoryModal
              open={valuationHistoryModalOpen}
              onClose={() => setValuationHistoryModalOpen(false)}
              valuationHistory={valuationHistory}
              valuationHistoryLoading={valuationHistoryLoading}
            />
            <Grid container pb="40px">
              <Grid item xs={9} sx={{ overflowX: 'scroll' }}>
                <GridForm
                  onSubmit={(formValues) => {
                    submitComputeValuation(formValues)
                  }}
                  onUpdatedFormatted={validateFormattedFormData}
                  inputConfigs={computeValuationFormInputConfigs}
                  ctaText="Compute Valuation"
                  ctaButtonProps={{ sx: { width: '140px' } }}
                  formProps={computeValuationFormFormProps}
                  loading={computeValuationLoading}
                />
              </Grid>
              <Grid item xs={3} position="relative">
                <Box position="absolute">
                  <Box
                    position="fixed"
                    top="100px"
                    bottom="0"
                    sx={{ overflowY: 'scroll' }}
                  >
                    <ValuationSummary
                      computedValuationResult={computedValuationResult}
                      operatorUuid={operatorUuid}
                      valuationInputs={valuationInputs}
                      canSubmitValuation={canSubmitValuation}
                      // selfReportedValue={selfReportedValue}
                      selfReportedValue={0}
                      openValuationHistoryModal={openValuationHistoryModal}
                      openRejectValuationModal={openRejectValuationModal}
                      addressData={addressData}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </>
    )
  }
}

export default MultiFamilyComputeAddressValuationTab
