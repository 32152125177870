import React, { useState, useEffect } from 'react'
import { useParams } from '@reach/router'
import { gql, useQuery } from '@apollo/client'
import {
  AdminAddressValuationsGetOperatorDocument,
  AdminAddressValuationsGetSalesforceAddressDocument,
  AdminAddressValuationsGetAddressValuationHistoryDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'

export const GET_OPERATOR = gql`
  query AdminAddressValuationsGetOperator(
    $input: Core_GetOperatorRequestInput!
  ) {
    operator(input: $input) {
      operator {
        uuid
      }
    }
  }
`

export const GET_ADDRESS = gql`
  query AdminAddressValuationsGetSalesforceAddress(
    $input: Core_GetSalesforceAddressRequestInput!
  ) {
    salesforceAddress(input: $input) {
      address {
        id
        formattedAddress
        parentAddressId
        propertyType
        propertyCondition
        beds
        baths
        sqft
        addressStreet
        units {
          unit
          formattedAddress
          parentAddressId
          propertyType
          propertyCondition
          beds
          baths
          sqft
          addressStreet
          addressStateCode
          addressPostalCode
        }
        addressStateCode
        addressPostalCode
        addressCounty
        unit
        yearBuilt
        leadReportedMortgage
        selfReportedValue
        additionalInfo
      }
    }
  }
`

export const GET_VALUATION_HISTORY = gql`
  fragment ValuationTrailFields on Core_ValuationTrailNode {
    name
    description
    value
  }
  query AdminAddressValuationsGetAddressValuationHistory(
    $input: Core_GetAddressValuationHistoryRequestInput!
  ) {
    getAddressValuationHistory(input: $input) {
      multiFamilyValuations {
        expiresAt
        finalOfferPrice
        leadUuid
        operatorUuid
        operator {
          fullName
        }
        outputs {
          impliedYieldMinimums
          msaLevelMinimums
          finalOfferPrice
          grossYield
          capRate
          netYield
          noiMarginAverage
          pass
          netYieldAdjustedOfferPrice
          submarketRentDeduction
          grossYieldOnAdjustedOfferPrice
          unconstrainedMsaYieldMin
          inPlaceNetYield
          unconstrainedMsaYieldMinOfferPrice
          uwCashOnCashYield {
            customerUwCashOnCashYield
            currentUwCashOnCashYield
          }
          remodelCosts {
            score
            notes
            immediateAddressableCapex
            totalAddressableCapex
            healthAndSafetyCapex
          }
          daysInRemodelDeduction
        }
        property {
          uuid
          addressUuid
          propertyType
          beds
          baths
          halfBaths
          sqft
          yearBuilt
          notes
        }
        propertyInput {
          propertyRemodelCost {
            healthAndSafetyCapex
            notes
            immediateAddressableCapex
            score
            totalAddressableCapex
          }
          otherCosts {
            acquisition
            miscellaneous
            hoa
            propertyTaxes
            notes
            hoaExists
            addressNearbyHomeHoa
            urlNearbyHomeHoa
            additionalMonthlyCost
            additionalMonthlyCostNotes
          }
          selfReportedValue
          cashToClose
          fiveYearTreasuryRate
        }
        rejectionReason
        salesApproved
        type
        unitInputs {
          remodelCost {
            score
            notes
            immediateAddressableCapex
            totalAddressableCapex
            healthAndSafetyCapex
          }
          monthsRemainingOnLease
          currentlyOccupied
          currentRent
          avmProjectedRent
          analystProjectedRent
          beds
          baths
          squareFootage
          addressUuid
          rentNotes
          marketRentComps {
            source
            marketRent
            url
            address
            notes
            squareFootage
            rentalDate
            uuid
            addressUuid
            selected
            updatedAt
            distance
          }
          offerPriceComps {
            source
            similarityScore
            url
            avmPrice
            squareFootage
            address
            beds
            baths
            halfBaths
            yearBuild
            notes
            estimatedRemodelCost
            soldDate
            uuid
            addressUuid
            selected
            updatedAt
            distance
            conditionScore
            numUnits
          }
          additionalMonthlyCost
          additionalMonthlyCostNotes
        }
        updatedAt
        uuid
        valuationTrail {
          ...ValuationTrailFields
          nodes {
            ...ValuationTrailFields
            nodes {
              ...ValuationTrailFields
              nodes {
                ...ValuationTrailFields
                nodes {
                  ...ValuationTrailFields
                  nodes {
                    ...ValuationTrailFields
                    nodes {
                      ...ValuationTrailFields
                      nodes {
                        ...ValuationTrailFields
                        nodes {
                          ...ValuationTrailFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      singleFamilyValuations {
        expiresAt
        finalOfferPrice
        uuid
        leadUuid
        type
        inputs {
          offerPrice {
            housecanaryCondition
            comps {
              source
              similarityScore
              url
              avmPrice
              squareFootage
              address
              beds
              baths
              halfBaths
              yearBuild
              notes
              estimatedRemodelCost
              soldDate
              uuid
              addressUuid
              selected
              updatedAt
              distance
              conditionScore
              numUnits
            }
            housecanaryAdjustedAvmPrice
            notes
            selfReportedValue
            useCma
            cashToClose
            fiveYearTreasuryRate
          }
          remodelCost {
            score
            notes
            immediateAddressableCapex
            totalAddressableCapex
            healthAndSafetyCapex
          }
          marketRent {
            housecanaryProjectedRent
            comps {
              source
              marketRent
              url
              address
              notes
              squareFootage
              rentalDate
              uuid
              addressUuid
              selected
              updatedAt
              distance
            }
            analystProjectedRent
            notes
            currentRent
            monthsRemainingOnLease
            currentlyOccupied
          }
          otherCosts {
            acquisition
            miscellaneous
            hoa
            propertyTaxes
            notes
            hoaExists
            addressNearbyHomeHoa
            urlNearbyHomeHoa
            additionalMonthlyCost
            additionalMonthlyCostNotes
          }
        }
        outputs {
          impliedYieldMinimums
          msaLevelMinimums
          finalOfferPrice
          grossYield
          capRate
          netYield
          noiMarginAverage
          pass
          netYieldAdjustedOfferPrice
          submarketRentDeduction
          grossYieldOnAdjustedOfferPrice
          unconstrainedMsaYieldMin
          inPlaceNetYield
          uwCashOnCashYield {
            customerUwCashOnCashYield
            currentUwCashOnCashYield
          }
          unconstrainedMsaYieldMinOfferPrice
          remodelCosts {
            score
            notes
            immediateAddressableCapex
            totalAddressableCapex
            healthAndSafetyCapex
          }
          daysInRemodelDeduction
        }
        rejectionReason
        operatorUuid
        operator {
          fullName
        }
        salesApproved
        property {
          uuid
          addressUuid
          propertyType
          beds
          baths
          halfBaths
          sqft
          yearBuilt
          notes
        }
        updatedAt
        valuationTrail {
          ...ValuationTrailFields
          nodes {
            ...ValuationTrailFields
            nodes {
              ...ValuationTrailFields
              nodes {
                ...ValuationTrailFields
                nodes {
                  ...ValuationTrailFields
                  nodes {
                    ...ValuationTrailFields
                    nodes {
                      ...ValuationTrailFields
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const useComputeAddressValuationPage = () => {
  let { addressId } = useParams()
  addressId = addressId as string
  const { notify } = useSnackbar()
  const [tabValue, setTabValue] = useState('1')

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  const { data: operatorData } = useQuery(
    AdminAddressValuationsGetOperatorDocument,
    {
      variables: {
        input: {},
      },
      onError: () => {
        notify('Failed to get operator data', 'error')
      },
    }
  )

  const { data: addressData } = useQuery(
    AdminAddressValuationsGetSalesforceAddressDocument,
    {
      variables: {
        input: {
          addressId,
        },
      },
      onError: () => {
        notify('Failed to get address data', 'error')
      },
    }
  )

  const { data: valuationHistory, loading: valuationHistoryLoading } = useQuery(
    AdminAddressValuationsGetAddressValuationHistoryDocument,
    {
      variables: {
        input: {
          addressId,
        },
      },
    }
  )

  // set default value of tab on page load to multifamily if the address has units
  useEffect(() => {
    if (
      addressData?.salesforceAddress?.address?.units &&
      addressData.salesforceAddress.address.units.length > 0
    ) {
      setTabValue('2')
    }
  }, [addressData])

  return {
    tabValue,
    handleTabChange,
    operatorData,
    valuationHistory,
    valuationHistoryLoading,
    addressData,
  }
}

export default useComputeAddressValuationPage
